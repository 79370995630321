<template>
  <div class="table-responsive">
    <table v-if="list && list.length > 0">
      <thead>
        <tr>
          <th>{{ $t("block") }}</th>
          <th>{{ $t("extrinsicID") }}</th>
          <th>{{ $t("extrinsicHash") }}</th>
          <th>{{ $t("action") }}</th>
          <th>{{ $t("result") }}</th>
          <th>{{ $t("time") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in list" :key="item.id">
          <td>
            <router-link
              :to="'/block/' + getItem(item).block_id"
              >{{ getItem(item).block_id }}</router-link
            >
          </td>
          <td v-if="item.id">
            <router-link :to="'/extrinsic/' + item.id">{{
              item.id
            }}</router-link>
          </td>
          <td v-else>(pending)</td>

          <td v-if="getItem(item).extrinsic_hash">
            <router-link
              :to="'/extrinsic/0x' + getItem(item).extrinsic_hash"
              >{{
                webUtil.formatStrByDot("0x" + getItem(item).extrinsic_hash)
              }}</router-link
            >
          </td>
          <td v-else>(pending)</td>

          <td v-if="getItem(item).call_id">
            <span class="type">
              {{ getItem(item).module_id }} ({{ getItem(item).call_id }})
            </span>
          </td>
          <td v-else>(pending)</td>
          <td class="text-green" v-if="getItem(item).success">Success</td>
          <td class="text-orange" v-else>Fail</td>
          <td>
            {{ getItem(item).datetime?webUtil.timestampToDate(getItem(item).datetime):'(pending)' }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="null" v-else>{{ $t("noInformation") }}</div>
    <Loading :loading="isLoading"/>
  </div>
</template>

<script>
import Loading from "./loading";
export default {
  props: ["list", "isLoading"],
  components: {
    Loading,
  },
  methods: {
    getItem(item) {
      return item.attributes;
    },
  },
};
</script>
<style scoped>
.table-responsive td{
  padding: 8px 30px 8px 0;
}
</style>