<template>
  <section>
    <div class="banner">
      <div class="container">
        <div class="card">
          <h3>L1 {{ $t("address") }}</h3>
          <p>
            <span class="copy-text">{{ address }}</span>
            <img
              class="copy-btn"
              @click="copyAction(address)"
              src="@/static/img/icon/copy_grey.png"
              width="20"
            />
          </p>
        </div>
      </div>
    </div>
    <article class="container">
      <!-- Assets -->
      <ul class="layout-two-part">
        <li class="card table-list">
          <h3>{{ $t("overview") }}</h3>
          <ul v-if="assets" class="layout-about-item-label">
            <li>
              <label>{{ $t("balance") }}</label>
              <p>
                {{ webUtil.formatNumber(assets.availableBalance, tokenDecimals) }}
                {{ tokenSymbol }}
              </p>
            </li>
            <li v-if="assets.lockedBalance.toString()">
              <label>{{ $t("locked") }}</label>
              <p>{{ assets.lockedBalance.toString()>0?webUtil.formatNumber(assets.lockedBalance, tokenDecimals):0 }} {{ tokenSymbol }}</p>
            </li>
            <li>
              <label>{{ $t("valuePrice") }}</label>
              <p>{{ totalVal }}</p>
            </li>
            <!-- <li>
              <label>{{$t('token')}}</label>
              <div class="dropdown">
                <p>{{selectedToken}}</p>
                <ul class="dropdown-menu">
                  <li>
                    <a href="javascript:;">1</a>
                  </li>
                  <li>
                    <a href="javascript:;">2</a>
                  </li>
                  <li>
                    <a href="javascript:;">3</a>
                  </li>
                </ul>
              </div>
            </li> -->
          </ul>
          <ul v-else class="null">{{$t('noInformation')}}</ul>
        </li>
        <li class="card table-list" >
          <h3>{{ $t("moreInfo") }}</h3>
          <ul v-if="EVMAddress" class="layout-about-item-label">
            <p>L2 {{ $t("address") }}</p>
            <p class="mt-8">
              <router-link
                class="text-blue"
                :to=" '/evm/' + EVMAddress"
                >{{ EVMAddress }}</router-link
              >
              <img
                class="copy-btn"
                @click="copyAction(EVMAddress)"
                src="@/static/img/icon/copy_grey.png"
                width="16"
              />
            </p>
          </ul>
          <ul v-else class="null">{{$t('noInformation')}}</ul>
        </li>
      </ul>

      <!-- extrinsics -->
      <main class="card table-list">
        <div class="tabs">
          <h3
            :class="{ 'selected-tab': selectedTab == 'extrinsics' }"
            @click="selectTab('extrinsics')"
          >
            {{ $t("extrinsics") }}
          </h3>
          <h3
            :class="{ 'selected-tab': selectedTab == 'transfers' }"
            @click="selectTab('transfers')"
          >
            {{ $t("transfers") }}
            <!-- <span class="text-12">{{ transfersTotal }}</span> -->
          </h3>
        </div>
        <!-- 交易记录 -->
        <extrinsics-table
          v-if="selectedTab == 'extrinsics'"
          :list="extrinsics"
          :isLoading="txLoading"
        />
        <!-- 转账记录 -->
        <transfer-table
          v-else
          :list="transfers"
          :self="address"
          :isLoading="txLoading"
        />
        <!-- 翻页 -->
        <pagination-view
          :type="selectedTab"
          :limit="limit"
          :total="total"
          :page="page"
          @emitPage="getList"
        />
      </main>
    </article>
  </section>
</template>

<script>
import extrinsicsTable from "base/extrinsics-table";
import transferTable from "base/transfer-table";
import paginationView from "base/pagination";
export default {
  props: ["address"],
  data() {
    return {
      txLoading: false,
      txLoading: true,
      assets: null,
      transfers: null,
      extrinsics: null,
      transfersTotal: 0,
      extrinsicsTotal: 0,
      selectedTab: "extrinsics",
      selectedToken: "1",
      limit: 10,
      price: 0,
      EVMAddress:'',
      page: 1,
    };
  },
  computed: {
    total() {
      return this[this.selectedTab + "Total"];
    },
    totalVal() {
      if (this.assets && this.assets.freeBalance && this.price) {
        return (
          "$ " +
          this.webUtil.BN(this.assets.freeBalance)
            .times(this.price)
            .div(Math.pow(10, this.tokenDecimals))
            .toFormat(4)
        );
      }
      return "--";
    },
    hash(){
      return this.$route.hash?this.$route.hash.slice(1):'';
    }
  },
  created() {
    this.getPrice();
    this.init();
  },
  methods: {
    init(){
      this.selectedTab = this.hash||'extrinsics';
      this.selectTab();
      this.getBalance();
      this.getEVMAddress();
    },
    changeToken(item) {
      this.selectedToken = item;
    },
    selectTab(tab) {
      if(tab){
        this.selectedTab = tab;
        this.$router.push('/account/'+this.address+'#'+tab)
      }
      this.getList();
    },
    getPrice() {
      this.axios
        .get(
          "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=" +
            this.tokenSymbol.toLowerCase()
        )
        .then((res) => {
          this.price = res.data[0].current_price;
        })
        .catch(() => {
          console.log("Get price failed");
        });
    },
    getAccount() {
      // this.axios
      //   .get(this.domain + "v1/account/" + this.address + "?include=indices")
      //   .then((res) => {
      //     this.assets = res.data.data.attributes;
      //     console.log(res.data.data.attributes);
      //     this.getBalance()
      //   }).catch(()=>{
      //     this.getBalance()
      //   })
    },
    getBalance(){
      this.user.getAllBalance(this.address).then(res=>{
        this.assets = res;
      })
      .catch(() => {
        this.assets = null;
        console.log("Get assets failed");
      });
    },
    getEVMAddress() {
      this.user.getEVMAccount(this.address).then((res) => {
        if (res) {
          this.EVMAddress=res.ethereum.Ethereum;
        } else {
          this.EVMAddress="";
        }
      });
    },
    getList(page=1) {
      this.page=page;
      if (this.selectedTab == "extrinsics") {
        this.getExtrinsics(page);
      } else {
        this.getTransfers(page);
      }
    },
    getExtrinsics(page = 1) {
      this.txLoading = true;

      this.axios
        .get(
          this.domain +
            "v1/extrinsic?filter[address]=" +
            this.address +
            "&page[size]=" +
            this.limit +
            "&page[number]=" +
            page
        )
        .then((res) => {
          this.extrinsics = res.data.data;
          this.extrinsicsTotal = res.data.total?res.data.total:0;
          this.txLoading = false;
        })
        .catch((err) => {
          this.extrinsics = null;
          this.txLoading = false;
          console.log("Get extrinsic failed");
        });
    },
    getTransfers(page = 1) {
      this.txLoading = true;
      this.axios
        .get(
          this.domain +
            "v1/balances/transfer?filter[address]=" +
            this.address +
            "&filter[signed]=1&page[size]=" +
            this.limit +
            "&page[number]=" +
            page
        )
        .then((res) => {
          this.transfers = res.data.data;
          this.transfersTotal = res.data.total?res.data.total:0;
          this.txLoading = false;
        })
        .catch((err) => {
          this.transfers = null;
          this.txLoading = false;
          console.log("Get transfers failed");
        });
    },
  },
  components: {
    extrinsicsTable,
    transferTable,
    paginationView,
  },
  watch: {
    address() {
      this.init();
    },
  },
};
</script>
